const CloseIcon = () => (
  <div className='flex justify-end px-2 grow'>
    <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none'>
      <path
        fill='#211F26'
        fillRule='evenodd'
        d='M.936 8a7.064 7.064 0 1 1 14.128 0A7.064 7.064 0 0 1 .936 8ZM8 1.949A6.051 6.051 0 1 0 8 14.05 6.051 6.051 0 0 0 8 1.949Zm2.51 3.54a.533.533 0 0 1 0 .755L8.755 8l1.757 1.756a.534.534 0 0 1-.755.755L8 8.754l-1.756 1.757a.533.533 0 1 1-.754-.755L7.246 8 5.49 6.244a.533.533 0 0 1 .754-.754L8 7.246 9.756 5.49a.533.533 0 0 1 .755 0Z'
        clipRule='evenodd'
      />
    </svg>
  </div>
);

export default CloseIcon;
