import { Timestamp } from '@bufbuild/protobuf';
import { parse } from 'date-fns';

export const parseDateString = (originalValue: string | Date) => {
  if (originalValue != 'Invalid Date' && originalValue instanceof Date)
    return originalValue;
  if (typeof originalValue === 'string')
    return parse(originalValue.replace('-', '/'), 'MM-dd-yyyy', new Date());

  return null;
};

export const convertDateToDisplay = (date: Date) => {
  if (!date || date.toString() === 'Invalid Date') return;

  if (date instanceof Date) {
    const dateElems = new Date(date)
      .toISOString()
      .replace(/T.*/, '')
      .split('-');
    dateElems.push(dateElems.shift() as string);
    return dateElems.join('/');
  }
};

export const convertTimestampToDayFormat = (timestamp: Timestamp) => {
  if (!timestamp) return 'Unknown Date';
  const today = new Date();
  const date = timestamp.toDate();
  const isToday = today.getDate() === date.getDate();

  const weekday = isToday
    ? 'Today'
    : date.toLocaleDateString('en-US', {
        weekday: 'long',
      });
  const time = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  });
  return weekday + ', ' + time;
};

export const convertTimestampToDateFormat = (timestamp: Timestamp) => {
  if (!timestamp) return 'Unknown Date';

  return timestamp.toDate().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const TIME_72_HOURS = 72 * 60 * 60 * 1000;
