const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const formatNumberToRoundedDollars = (amount: number): string => {
  if (amount === 0) return '$0';
  if (!amount) return '$?';

  return Math.round(amount)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    .replace(/\.0+$/, '');
};

export default currencyFormatter;
