export const CopyIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none'>
    <g clipPath='url(#a)' filter='url(#b)'>
      <mask id='c'>
        <path d='M-736-870H264v2294H-736V-870Z' />
      </mask>
      <path d='M-736-870H264v2294H-736V-870Z' />
      <path fill='#DFDCE3' d='M263-870v2294h2V-870h-2Z' mask='url(#c)' />
      <path fillOpacity={0.01} d='M-672-110h872V64h-872z' />
      <path fillOpacity={0.01} d='M0 0h16v16H0z' />
      <path
        fill='#211F26'
        fillRule='evenodd'
        d='M1.067 10.133a1.6 1.6 0 0 0 1.6 1.6h1.6v-1.066h-1.6a.533.533 0 0 1-.534-.534V2.667c0-.295.24-.534.534-.534h7.466c.295 0 .534.24.534.534v1.6h-4.8a1.6 1.6 0 0 0-1.6 1.6v7.466a1.6 1.6 0 0 0 1.6 1.6h7.466a1.6 1.6 0 0 0 1.6-1.6V5.867a1.6 1.6 0 0 0-1.6-1.6h-1.6v-1.6a1.6 1.6 0 0 0-1.6-1.6H2.667a1.6 1.6 0 0 0-1.6 1.6v7.466Zm4.266-4.266c0-.295.24-.534.534-.534h7.466c.295 0 .534.24.534.534v7.466a.533.533 0 0 1-.534.534H5.867a.533.533 0 0 1-.534-.534V5.867Z'
        clipRule='evenodd'
      />
    </g>
    <path
      stroke='#DFDCE3'
      d='M-735.5-1126c0-7.56.001-13.17.581-17.49.579-4.3 1.724-7.24 3.959-9.47 2.235-2.23 5.169-3.38 9.47-3.96 4.316-.58 9.933-.58 17.49-.58h936c7.557 0 13.174 0 17.49.58 4.301.58 7.235 1.73 9.47 3.96 2.235 2.23 3.38 5.17 3.959 9.47.58 4.32.581 9.93.581 17.49v2518c0 7.56-.001 13.17-.581 17.49-.579 4.3-1.724 7.24-3.959 9.47-2.235 2.23-5.169 3.38-9.47 3.96-4.316.58-9.933.58-17.49.58h-936c-7.557 0-13.174 0-17.49-.58-4.301-.58-7.235-1.73-9.47-3.96-2.235-2.23-3.38-5.17-3.959-9.47-.58-4.32-.581-9.93-.581-17.49v-2518Z'
    />
    <defs>
      <clipPath id='a'>
        <path d='M-736-1126c0-15.08 0-22.63 4.686-27.31 4.687-4.69 12.229-4.69 27.314-4.69h936c15.085 0 22.627 0 27.314 4.69C264-1148.63 264-1141.08 264-1126v2518c0 15.08 0 22.63-4.686 27.31-4.687 4.69-12.229 4.69-27.314 4.69h-936c-15.085 0-22.627 0-27.314-4.69C-736 1414.63-736 1407.08-736 1392v-2518Z' />
      </clipPath>
      <filter
        id='b'
        width={1128}
        height={2422}
        x={-800}
        y={-918}
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy={12} />
        <feGaussianBlur stdDeviation={30} />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.055 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_1_4' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy={16} />
        <feGaussianBlur stdDeviation={32} />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix values='0 0 0 0 0.183002 0 0 0 0 0.019581 0 0 0 0 0.346419 0 0 0 0.024 0' />
        <feBlend in2='effect1_dropShadow_1_4' result='effect2_dropShadow_1_4' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feMorphology
          in='SourceAlpha'
          radius={20}
          result='effect3_dropShadow_1_4'
        />
        <feOffset dy={16} />
        <feGaussianBlur stdDeviation={18} />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix values='0 0 0 0 0.0478013 0 0 0 0 0.00260399 0 0 0 0 0.183396 0 0 0 0.173 0' />
        <feBlend in2='effect2_dropShadow_1_4' result='effect3_dropShadow_1_4' />
        <feBlend
          in='SourceGraphic'
          in2='effect3_dropShadow_1_4'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);
