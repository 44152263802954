const postMessageToApp = (type: string, url?: string) => {
  let messageBody;

  if (url) {
    messageBody = JSON.stringify({ type: type, url: url || '' });
  } else {
    messageBody = type;
  }

  if (
    window &&
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.messageAppFromWeb &&
    window.webkit.messageHandlers.messageAppFromWeb.postMessage
  ) {
    window.webkit.messageHandlers.messageAppFromWeb.postMessage(messageBody);
  } else if (
    window &&
    window.messageAppFromWeb &&
    window.messageAppFromWeb.postMessage
  ) {
    window.messageAppFromWeb.postMessage(messageBody);
  } else {
    console.warn('error_messaging_mobile_app');
  }
};

export default postMessageToApp;
